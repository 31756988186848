import "./AboutCard.css";

export const AboutCard = () => {
  return (
    <div className="AboutCard">
      <div className="AboutHeader">About</div>
      <div className="AboutText">
        MedRPT.ai was founded in 2024 by a team of academic health
        professionals:
        <ul>
          <li>
            Dr. Harry Levy created, and still directs, for Albert Einstein
            College of Medicine, the award-winning Cyberounds® and ONCOmmunity,
            the Internet’s first complete digital training sites for graduate
            continuing medical education, which 30 years ago pioneered remote
            learning and game-based education (“medutainment”) authored by
            academic experts from 14 U.S. medical schools.
          </li>
          <li>
            Dr. Ted Witek, following a distinguished scientific and executive
            career as at Boehringer Ingelheim, is a Professor and Director of
            the Doctor of Public Health program at the University of Toronto.
            Dr. Witek has published over 100 manuscripts, chapter and texts in
            respiratory public health and drug development. He serves as a
            Director and advisor to several firms.
          </li>
          <li>
            Mr. Farbod Abolhassani is a leader in AI, software engineering, and
            healthcare, actively contributing to multiple organizations. He is
            an Adjunct Professor at the University of Toronto, an Affiliate
            Scientist at Trillium Health Partners, and the Director of
            Innovation at TeleVU Innovation, where he drives the development of
            AI-powered telemedicine and healthcare solutions.
          </li>
        </ul>
      </div>
    </div>
  );
};
