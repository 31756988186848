import "./PICOCard.css";
import { useContext } from "react";
import { PicoContext } from "../../Context/PicoContext";
export const PICOCard = () => {
  const { pico, setPico } = useContext(PicoContext);
  const { P, I, C, O, IC, EC } = pico;
  return (
    <div className="PICOCard">
      <div className="Body">
        <div className="ChartContainer">
          <img
            src={`${process.env.PUBLIC_URL}/chart.png`}
            alt="Chart"
            className="Chart"
          />
          <div className="Header">PICO Details</div>
        </div>
        <hr />
        <div className="ItemContainer">
          <div className="Title">Population / Problem</div>
          <input
            value={P}
            onChange={(e) =>
              setPico((prev) => ({ ...prev, P: e.target.value }))
            }
            required
          />
        </div>
        <div className="ItemContainer">
          <div className="Title">Intervention / Exposure</div>
          <input
            value={I}
            onChange={(e) =>
              setPico((prev) => ({ ...prev, I: e.target.value }))
            }
            required
          />
        </div>
        <div className="ItemContainer">
          <div className="Title">Comparision</div>
          <input
            value={C}
            onChange={(e) =>
              setPico((prev) => ({ ...prev, C: e.target.value }))
            }
            required
          />
        </div>
        <div className="ItemContainer">
          <div className="Title">Outcome</div>
          <input
            value={O}
            onChange={(e) =>
              setPico((prev) => ({ ...prev, O: e.target.value }))
            }
            required
          />
        </div>
        <div className="ItemContainer">
          <div className="Title">Inclusion Criteria</div>
          <input
            value={IC}
            onChange={(e) =>
              setPico((prev) => ({ ...prev, IC: e.target.value }))
            }
            required
          />
        </div>
        <div className="ItemContainer">
          <div className="Title">Exclusion Criteria</div>
          <input
            value={EC}
            onChange={(e) =>
              setPico((prev) => ({ ...prev, EC: e.target.value }))
            }
            required
          />
        </div>
      </div>
    </div>
  );
};
