import "./ArticleCard.css";
import { useState, useEffect } from "react";

export const ArticleCard = ({
  score,
  title,
  date,
  authors,
  journal,
  insight,
  results,
  citations,
  isPeerReviewed,
  pubmed_id,
  index,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  // eslint-disable-next-line
  const [citationLevel, setCitationLevel] = useState("");

  useEffect(() => {
    if (citations > 15) {
      setCitationLevel("HighlyCited");
    } else if (citations > 5) {
      setCitationLevel("ModeratelyCited");
    } else {
      setCitationLevel("SparselyCited");
    }
  }, [citations]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={`ArticleCard ${isCollapsed ? "collapsed" : ""}`}
      onClick={toggleCollapse}
    >
      <div className="CollapseIcon">{isCollapsed ? "▼" : "▲"}</div>
      <div className="Body">
        <div className="Title">
          {index + 1}. {title}
        </div>
        <div className="Container">
          <div
            className="Headervalue"
            style={{ color: "#4a5568", fontWeight: 400, fontFamily: "Inter" }}
          >
            Author(s): {authors[0].split(" ")[authors[0].split(" ").length - 1]}{" "}
            et al.
          </div>
        </div>
        <div className="Container">
          <span
            className={`Badge TrustScore ${
              score >= 70 ? "High" : score >= 35 ? "Moderate" : "Fair"
            }`}
          >
            Trust Score: {score}%
          </span>
          {/* <span className={`Badge ${citationLevel}`}>
                        {citationLevel.replace('Cited', ' Cited')}
                    </span> */}
          {/* <span className={`Badge TrustScore High`}>
                        <a href={`https://pubmed.ncbi.nlm.nih.gov/${pubmed_id}`} target="_blank" rel="noopener noreferrer">
                            View Full Study
                        </a>
                    </span> */}

          {/* {isPeerReviewed ? (
                        <span className='Badge PeerReviewed'>Peer Reviewed</span>
                    ) : (
                        <span className='Badge NotReviewed'>Not Reviewed</span>
                    )} */}
        </div>
        {!isCollapsed && (
          <>
            <div className="Container">
              <div className="Subheader">Date:</div>
              <div className="Headervalue">{date}</div>
            </div>
            <div className="Container">
              <div className="Subheader">Author(s):</div>
              <div className="Headervalue">{authors.join(", ")}</div>
            </div>
            <div className="Container">
              <div className="Subheader">Journal:</div>
              <div className="Headervalue">{journal}</div>
            </div>
            <div className="Container">
              <div className="Subheader">Citations #:</div>
              <div className="Headervalue">{citations}</div>
            </div>
            <div className="Insight">
              <div className="InsightHeader">Key Insights</div>
              <div className="InsightValue">{insight}</div>
            </div>
            <div className="Insight">
              <div className="InsightHeader">Analysis Results</div>
              <div className="InsightValue">{results}</div>
            </div>
            <span className="ViewFullStudyButton">
              <a
                href={`https://pubmed.ncbi.nlm.nih.gov/${pubmed_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Full Study
              </a>
            </span>
          </>
        )}
      </div>
    </div>
  );
};
