import React, { useRef, useState } from "react";
import "./Card.css";
import { FilterCard } from "../FilterCard/FilterCard";
import { AdvancedCard } from "../AdvancedCard/AdvancedCard";
import { useLocation } from "react-router-dom";

export const Card = ({ search, defaultText, setQuery = () => {}, query }) => {
  const location = useLocation();
  const isSearchPage = location.pathname.startsWith("/search");
  const [text, setText] = useState(defaultText || "");
  const [isModelopen, setIsModelOpen] = useState(false);
  const [isAdvancedModalOpen, setIsAdvancedModalOpen] = useState(false);
  const filterCardRef = useRef();
  const advancedCardRef = useRef();

  const closeFilterCard = (e) => {
    if (e.target === filterCardRef.current) {
      setIsModelOpen(false);
    }
  };

  const closeAdvancedCard = (e) => {
    if (e.target === advancedCardRef.current) {
      setIsAdvancedModalOpen(false);
    }
  };
  const handleClick = () => {
    const trimmedText = text.trim();
    setText(trimmedText);
    search(trimmedText);
  };

  const toggleModal = () => {
    setIsModelOpen(!isModelopen);
  };

  const toggleModalAdvanced = () => {
    setIsAdvancedModalOpen(!isAdvancedModalOpen);
  };

  return (
    <div className="Card">
      <div className="Header">
        <span className="GreenText">A</span>ccurate{" "}
        <span className="GreenText">I</span>nformation
      </div>
      <div className="SearchContainer">
        <div className="InputQuestionDiv">
          <textarea
            rows={5}
            cols={5}
            className="InputQuestion"
            placeholder="Ask your question..."
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </div>
      </div>
      {isSearchPage && (
        <div className="CardButtons">
          <div className="Button FilterButton" onClick={toggleModal}>
            Filter
          </div>

          <div className="Button FilterButton" onClick={toggleModalAdvanced}>
            Advanced
          </div>
        </div>
      )}
      {isSearchPage && (
        <div className="Button SubmitButton" onClick={handleClick}>
          Submit your question
        </div>
      )}
      {!isSearchPage && (
        <div className="CardButtons">
          <div className="Button FilterButton" onClick={toggleModal}>
            Filter
          </div>
          <div className="Button SubmitButton" onClick={handleClick}>
            Submit your question
          </div>
        </div>
      )}
      {isModelopen && (
        <div
          className="FilterCardOverlay"
          ref={filterCardRef}
          onClick={closeFilterCard}
        >
          <FilterCard toggleModal={toggleModal} />
        </div>
      )}{" "}
      {isAdvancedModalOpen && (
        <div
          className="FilterCardOverlay"
          ref={advancedCardRef}
          onClick={closeAdvancedCard}
        >
          <AdvancedCard
            toggleModalAdvanced={toggleModalAdvanced}
            setQuery={setQuery}
            query={query}
          />
        </div>
      )}
    </div>
  );
};
