import "./FoundCard.css";
import { ProgressBar } from "../ProgressBar/ProgressBar";
export const FoundCard = ({ qualifiedAbstracts, totalAbstracts }) => {
  return (
    <div className="FoundCard">
      <div className="text">
        <span className="qualified">{qualifiedAbstracts}</span> abstracts
        qualified out of <span className="analyzed">{totalAbstracts}</span>{" "}
        analyzed
        <ProgressBar percent={(qualifiedAbstracts / totalAbstracts) * 100} />
      </div>
    </div>
  );
};
