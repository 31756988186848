import { PICOCard } from "../PICOCard/PicoCard";
import { QueryTermCard } from "../QuerytermCard/QueryTermCard";
import "./AdvancedCard.css";

export const AdvancedCard = ({ toggleModalAdvanced, setQuery, query }) => {
  const handleApply = async (e) => {
    e.preventDefault();
    toggleModalAdvanced();
  };
  return (
    <div className="AdvancedCard">
      <button
        onClick={toggleModalAdvanced}
        className="AdvancedToggleModalCloseButton"
      >
        X
      </button>
      <form onSubmit={handleApply}>
        <div className="AdvancedCardFilters">
          <PICOCard />
          <QueryTermCard setQuery={setQuery} query={query} />
        </div>

        <button className="AdvancedApplyButton" type="submit">
          Apply
        </button>
      </form>
    </div>
  );
};
