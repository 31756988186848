import { useState, useContext } from "react";
import { FilterContext } from "../../Context/FilterContext";
// import {
//   FormControl,
//   FormLabel,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   Box,
//   InputLabel,
//   Select,
//   MenuItem,
//   TextField,
// } from "@mui/material";
import { Box, TextField } from "@mui/material";
import "./FilterCard.css";
export const FilterCard = ({ toggleModal }) => {
  const { filters, setFilters } = useContext(FilterContext);
  // eslint-disable-next-line no-unused-vars
  const { selectedSex, fromYear, toYear, age, abstracts } = filters;

  const [error, setError] = useState({ from: "", to: "" });

  const handleClearAll = () => {
    setFilters({
      selectedSex: "",
      fromYear: "",
      toYear: "",
      age: "",
      abstracts: "",
    });
    setError({ from: "", to: "" });
  };

  const validateYears = () => {
    let isValid = true;
    const errors = { from: "", to: "" };

    const yearRegex = /^\d{4}$/;

    if (fromYear && !yearRegex.test(fromYear)) {
      errors.from = "Enter a valid 4-digit year";
      isValid = false;
    }

    if (toYear && !yearRegex.test(toYear)) {
      errors.to = "Enter a valid 4-digit year";
      isValid = false;
    }

    if (fromYear && toYear && parseInt(fromYear) > parseInt(toYear)) {
      errors.from = "From year cannot be greater than To year";
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  const handleApply = () => {
    if (validateYears()) {
      toggleModal();
    }
  };

  return (
    <div className="FilterCard">
      <button onClick={toggleModal} className="ToggleModalCloseButton">
        X
      </button>
      {/* <p className="AgeGenderFilterExplaination">
        Use the age and gender filters to find the relevant research
      </p> */}
      {/* <div className="AgeAbstractContainer">
        <Box sx={{ minWidth: 120, flexGrow: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="age-select-label">Age</InputLabel>
            <Select
              labelId="age-select-label"
              id="age-select"
              label="Age"
              value={age}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, age: e.target.value }))
              }
            >
              <MenuItem value="18-24">18 - 24</MenuItem>
              <MenuItem value="25-34">25 - 34</MenuItem>
              <MenuItem value="35-44">35 - 44</MenuItem>
              <MenuItem value="45-54">45 - 54</MenuItem>
              <MenuItem value="55-60">55 - 60</MenuItem>
              <MenuItem value="60+">60+</MenuItem>
            </Select>
          </FormControl>
        </Box> */}
        {/* <Box sx={{ minWidth: { xs: 120, sm: 300 }, flexGrow: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="abstract-select-label">
              Number of abstracts to screen
            </InputLabel>
            <Select
              labelId="abstract-select-label"
              id="abstract-select"
              label="Number of abstracts to screen"
              value={abstracts}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, abstracts: e.target.value }))
              }
            >
              <MenuItem value="100">100</MenuItem>
              <MenuItem value="250">250</MenuItem>
              <MenuItem value="500">500</MenuItem>
              <MenuItem value="1000">1000</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div> */}

      {/* <div className="Gender">
        <FormControl>
          <FormLabel
            id="gender-radio-buttons-group-label"
            sx={{ fontWeight: "bold" }}
          >
            Gender
          </FormLabel>
          <RadioGroup
            aria-labelledby="gender-radio-buttons-group-label"
            value={selectedSex}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, selectedSex: e.target.value }))
            }
            name="gender-radio-buttons-group"
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Female"
            />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
      </div> */}
      <div className="YearRange">
        <h4>Search Year Range</h4>
        <div className="Inputs">
          <TextField
            label="From"
            variant="outlined"
            value={fromYear}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, fromYear: e.target.value }))
            }
            error={!!error.from}
            helperText={error.from}
            FormHelperTextProps={{ style: { color: "red" } }}
          />
          <TextField
            label="To"
            variant="outlined"
            value={toYear}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, toYear: e.target.value }))
            }
            error={!!error.to}
            helperText={error.to}
            FormHelperTextProps={{ style: { color: "red" } }}
          />
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          mt: 2,
        }}
      >
        <button className="ClearButton" onClick={handleClearAll}>
          Clear All
        </button>
        <button className="ApplyButton" onClick={handleApply}>
          Apply
        </button>
      </Box>
    </div>
  );
};
