import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import Search from "../Search/Search";
import Disclaimer from "../Disclaimer/Disclaimer";
import About from "../About/About";
import { TopBar } from "../../Components/TopBar/TopBar";
import AppContainer from "../../Components/AppContainer/AppContainer";

function App() {
  return (
    <Router>
      <AppContainer>
        <TopBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search/:id" element={<Search />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;
