import "./ProgressBar.css";
export const ProgressBar = ({ percent }) => {
  return (
    <div className="ProgressBar">
      <div className="progress">
        <div className="progressBar" style={{ width: `${percent}%` }}>
          &nbsp;
        </div>
      </div>
    </div>
  );
};
