import "./DisclaimerCard.css";
export const DisclaimerCard = () => {
  return (
    <div className="DisclaimerCard">
      <div className="DisclaimerHeader">Disclaimer</div>
      <div className="DisclaimerText">
        Medical information is ever-changing, transformed frequently by new
        research and clinical experience. While MedRPT makes every effort to
        retrieve and present accurate information no warranty, expressed or
        implied, is offered. The member-subscriber-user should always use
        his/her own judgment, knowledge, experience and diagnostic
        decision-making before applying any information, whether provided by
        MedRPT, other member-subscribers or third parties, for any professional
        or personal use.
      </div>
      <div className="DisclaimerText">
        This service complies with the Health Insurance Portability and
        Accountability Act (HIPAA) and its regulations to protect the privacy
        and security of protected health information (PHI). Any PHI shared
        through this platform will be safeguarded in accordance with HIPAA
        requirements.
      </div>
    </div>
  );
};
