import { createContext, useState } from "react";

export const PicoContext = createContext();

export const PicoProvider = ({ children }) => {
  const [pico, setPico] = useState({
    P: "",
    I: "",
    C: "",
    O: "",
    IC: "",
    EC: "",
  });

  return (
    <PicoContext.Provider value={{ pico, setPico }}>
      {children}
    </PicoContext.Provider>
  );
};
