import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./Pages/App/App";
import reportWebVitals from "./reportWebVitals";
import { PicoProvider } from "./Context/PicoContext";
import { FilterProvider } from "./Context/FilterContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <FilterProvider>
      <PicoProvider>
        <App />
        <footer className="footer">© 2025 MedRPT LLC</footer>
      </PicoProvider>
    </FilterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
