import "./QueryTermCard.css";

export const QueryTermCard = ({ setQuery, query }) => {
  return (
    <div className="QueryTermCard">
      <div className="Body">
        <div className="QueryTermContainer">
          <img
            src={`${process.env.PUBLIC_URL}/filter.png`}
            alt="FilterQuery"
            className="FilterQueryImage"
          />
          <div className="Header">Query</div>
        </div>
        <hr />
        <div className="TextAreaContainer">
          <textarea
            className="QueryTextarea"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            required
          />
        </div>
      </div>
    </div>
  );
};
