import React from "react";
import "./TrustCard.css";

const RangeIndicator = ({ data }) => {
  return (
    <div className="rangeColumn">
      <div className="rangeLabels">
        {data.map((item, index) => (
          <div key={index}>
            <span
              className="indicator"
              style={{ backgroundColor: item.color }}
            />
            <span className="boldText">{item.label}</span>: {item.range}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RangeIndicator;
